import React, { useState, useEffect } from 'react';
import logo from '../assets/fyllo.png';
import menu from '../assets/Illustration/menu.svg';
import close from '../assets/Illustration/close.svg';
import down from '../assets/Illustration/down.svg';

import Image from 'next/image';
import Link from 'next/link';
import { motion, AnimatePresence, useScroll, useSpring } from 'framer-motion';
import NavAccordion from './Accordion/NavAccordion';
import { useRouter } from 'next/router';
import { Button } from './Button';

const NavData = [
  {
    title: 'About',
    path: 'about',
    children: [],
  },
  {
    title: 'Products',
    path: 'product',
    children: [
      {
        title: 'Software',
        path: 'product/software',
      },
      {
        title: 'Hardware',
        path: 'product/hardware',
      },
    ],
  },
  {
    title: 'Usecases',
    path: 'usecases',
    children: [
      {
        title: 'Farmer',
        path: 'usecases/farmer',
      },
      {
        title: 'Contracts/Corporates',
        path: 'usecases/corporates',
      },
      {
        title: 'FPOS',
        path: 'usecases/fpo',
      },
      {
        title: 'Seed/Variety',
        path: 'usecases/seed-and-variety',
      },

      {
        title: 'Crop Protection Plans',
        path: 'usecases/crop-protection-company',
      },

      {
        title: 'Plant Nutrition',
        path: 'usecases/plant-nutrition',
      },
      {
        title: 'Drip Irrigation',
        path: 'usecases/drip-irrigation',
      },
    ],
  },
  {
    title: "Farmer's Talk",
    path: 'farmers-talk',
    children: [],
  },
  {
    title: "Become a Channel Partner",
    path: 'channelPartner',
    children: [],
  },
  {
    title: 'Contact us',
    path: 'contact',
    children: [
      // {
      //   title: 'Book a Meeting Now',
      //   path: 'contact/contactUs',
      // },
      // {
      //   title: 'Call Us',
      //   path: 'contact',
      // },
    ],
  },
  {
    title: 'Blogs',
    path: 'blogs',
    children: [],
  },
];

export default function Navbar() {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [isproductOpen, setIsProductOpen] = useState(false);
  const [isusecaseOpen, setIsUsecaseOpen] = useState(false);
  const { scrollYProgress, scrollX } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const [TabActive, setTabActive] = useState('home');
  const router = useRouter();
  useEffect(() => {
    setTabActive(router.pathname);
    setShowMobileNav(false);
  }, [router.pathname]);

  const handleItemClick = (type) => {
    type === 'product' ? setIsProductOpen(false) : setIsUsecaseOpen(false);
  };

  return (
    <div className="fixed top-0 z-[100]">
      <AnimatePresence>
        {showMobileNav && (
          <motion.div
            className="z-[100] fixed top-16 mt-2 bg-white w-full p-4"
            initial={{ x: -300 }}
            animate={{
              x: 0,
            }}
            transition={{
              duration: 0.5,
            }}
            exit={{ x: -100 }}
            style={{
              display: 'flex',
            }}
          >
            <ul className="w-full">
              {NavData.map((it) => {
                return (
                  <NavAccordion
                    setShowMobileNav={setShowMobileNav}
                    key={JSON.stringify(it)}
                    heading={it.title}
                    content={it.children}
                    path={it.path}
                  />
                );
              })}
              <li className="grid place-items-center py-4">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSeA39JTJJIlOzfrHEsLU0lrmlJDr-Jz-gRuP-ycDICmA7ObYA/viewform">
                  <button className=" bg-fyllo-800 w-full p-0 px-4 py-1 flex rounded-lg text-white items-center justify-center">
                    BOOK DEVICE
                  </button>
                </a>
              </li>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>

      <nav
        className={
          'relative z-20 flex p-4 md:py-4 md:px-5 lg:px-10 w-screen justify-between  h-auto top-0 bg-white bg-opacity-90 backdrop-blur-lg  '
        }
      >
        <Link className="w-36" href={'/'}>
          <Image
            className="w-30"
            src={logo}
            alt="Fyllo"
            srcSet=""
            sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
          />
          <p className={
            'text-sm transition-all text-[#1A5D1A] font-semibold ' +
            'whitespace-nowrap gap-4'
          }>Where Technology Meets Agriculture</p>
          <span className="sr-only">Fyllo Logo</span>
        </Link>
        <div className="hidden md:flex items-center  gap-4 lg:gap-6 text-black">
          <Link href={`/about`}>
            <p
              className={
                'text-xs lg:text-base  transition-all    ' +
                (router.pathname.includes('about') ? 'text-fyllo-800 font-semibold' : 'text-gray-500')
              }
            >
              About
            </p>
          </Link>

          <div className="dropdown z-20  relative">
            <label
              tabIndex={0}
              className="flex justify-center items-center "
              onClick={() => setIsProductOpen(!isproductOpen)}
            >
              <Link href="/product">
                <p
                  className={
                    ' text-xs lg:text-base transition-all ' +
                    (router.pathname.includes('product') ? 'text-fyllo-800 font-semibold' : 'text-gray-500')
                  }
                >
                  Products
                </p>
              </Link>
              <Image
                src={down}
                className="w-4"
                alt="down"
                srcSet=""
                sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
              />
            </label>
            {isproductOpen && (
              <ul tabIndex={0} className="z-40  relative dropdown-content menu p-2 shadow rounded-sm bg-slate-50 w-max">
                <li>
                  <Link
                    className="text-black active:bg-slate-300"
                    href={`/product/software`}
                    onClick={() => handleItemClick('product')}
                  >
                    Software
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-black active:bg-slate-300"
                    href={`/product/hardware`}
                    onClick={() => handleItemClick('product')}
                  >
                    Hardware
                  </Link>
                </li>
              </ul>
            )}
          </div>
          <div className="dropdown items-center z-20  relative">
            <label
              tabIndex={0}
              className="flex justify-center items-center "
              onClick={() => setIsUsecaseOpen(!isusecaseOpen)}
            >
              <Link href={`/usecases`}>
                <p
                  className={
                    '  text-xs lg:text-base   transition-all    ' +
                    (router.pathname.includes('usecases') ? 'text-fyllo-800 font-semibold' : ' text-gray-500')
                  }
                >
                  Use Cases
                </p>
              </Link>
              <Image
                className="w-4"
                src={down}
                alt="down"
                srcSet=""
                sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
              />
            </label>
            {isusecaseOpen && (
              <ul tabIndex={0} className="z-40  relative dropdown-content menu p-2 shadow rounded-sm bg-slate-50 w-max">
                <li>
                  <Link
                    className="text-black active:bg-slate-300"
                    href={`/usecases/farmer`}
                    onClick={() => handleItemClick('usecase')}
                  >
                    Farmer
                  </Link>
                </li>
                <li>
                  <Link
                    className=" text-black active:bg-slate-300"
                    href={`/usecases/corporates`}
                    onClick={() => handleItemClick('usecase')}
                  >
                    Contracts / Corporates
                  </Link>
                </li>
                <li>
                  <Link
                    className=" text-black active:bg-slate-300"
                    href={`/usecases/fpo`}
                    onClick={() => handleItemClick('usecase')}
                  >
                    FPOs
                  </Link>
                </li>
                <li>
                  <Link
                    className=" text-black active:bg-slate-300"
                    href={`/usecases/seed-and-variety`}
                    onClick={() => handleItemClick('usecase')}
                  >
                    Seed / Variety
                  </Link>
                </li>
                <li>
                  <Link
                    className=" text-black active:bg-slate-300"
                    href={`/usecases/crop-protection-company`}
                    onClick={() => handleItemClick('usecase')}
                  >
                    {' '}
                    Crop Protection Company
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-black active:bg-slate-300"
                    href={`/usecases/plant-nutrition`}
                    onClick={() => handleItemClick('usecase')}
                  >
                    Plant Nutrition
                  </Link>
                </li>
                <li>
                  <Link
                    className=" text-black active:bg-slate-300"
                    href={`/usecases/drip-irrigation`}
                    onClick={() => handleItemClick('usecase')}
                  >
                    Drip Irrigation
                  </Link>
                </li>
              </ul>
            )}
          </div>
          <Link href={`/farmers-talk`}>
            <p
              className={
                '  text-xs lg:text-base    transition-all    ' +
                (router.pathname.includes('farmers-talk') ? 'text-fyllo-800 font-semibold' : 'text-gray-500')
              }
            >
              Farmers Talk
            </p>
          </Link>


          <div className="dropdown z-20  relative">
            <label tabIndex={0} className="flex justify-center items-center ">
              <Link href={`/channelPartner`}>
                <p
                  className={
                    'text-xs lg:text-base transition-all ' +
                    (router.pathname.includes('channelPartner') ? 'text-fyllo-800 font-semibold' : 'text-gray-500')
                  }
                >
                  Become a Channel Partner
                </p>
              </Link>
              {/* <Image src={down} className="w-4" alt="down" srcSet="" /> */}
            </label>
            {/* <ul className="relative z-[50] dropdown-content menu p-2 shadow rounded-sm bg-slate-50 w-max">
              <li>
                <Link className="text-black active:bg-slate-300" href={`/contact/contactUs`}>
                  Book A Meeting Now
                </Link>
              </li>
              <li>
                <Link href="tel:9380908040" className="text-stone-900 active:bg-slate-300">
                  Call Us
                </Link>
              </li>
            </ul> */}
          </div>
          <div className="dropdown z-20  relative">
            <label tabIndex={0} className="flex justify-center items-center ">
              <Link href={`/contact`}>
                <p
                  className={
                    'text-xs lg:text-base transition-all ' +
                    (router.pathname.includes('contact') ? 'text-fyllo-800 font-semibold' : 'text-gray-500')
                  }
                >
                  Contact
                </p>
              </Link>
              {/* <Image src={down} className="w-4" alt="down" srcSet="" /> */}
            </label>
            {/* <ul className="relative z-[50] dropdown-content menu p-2 shadow rounded-sm bg-slate-50 w-max">
              <li>
                <Link className="text-black active:bg-slate-300" href={`/contact/contactUs`}>
                  Book A Meeting Now
                </Link>
              </li>
              <li>
                <Link href="tel:9380908040" className="text-stone-900 active:bg-slate-300">
                  Call Us
                </Link>
              </li>
            </ul> */}
          </div>

          <Link href={`/blogs`}>
            <p
              className={
                ' text-xs lg:text-base transition-all ' +
                (router.pathname.includes('blogs') ? 'text-fyllo-800 font-semibold ' : 'text-gray-500')
              }
            >
              Blog
            </p>
          </Link>

          <a href="https://docs.google.com/forms/d/e/1FAIpQLSeA39JTJJIlOzfrHEsLU0lrmlJDr-Jz-gRuP-ycDICmA7ObYA/viewform">
            <button className=" bg-fyllo-800 w-full p-0 px-4 py-1 flex rounded-lg text-white items-center justify-center">
              BOOK DEVICE
            </button>
          </a>
        </div>
        <div className="block md:hidden">
          <label className="swap swap-rotate">
            <input
              type="checkbox"
              onClick={() => {
                setShowMobileNav(!showMobileNav);
              }}
            />
            {!showMobileNav ? (
              <svg
                // className="swap-off fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 512 512"
              >
                <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
              </svg>
            ) : (
              <svg
                // className="swap-on fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 512 512"
              >
                <polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
              </svg>
            )}
          </label>
          {/* <Image src={showMobileNav ? close : menu} alt="" srcSet="" className=" w-6" /> */}
        </div>
      </nav>
      <motion.div className=" z-10 left-0 right-0 h-[2px] bg-green-700 origin-left" style={{ scaleX }} />
    </div>
  );
}
